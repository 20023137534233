'use client'

import {Blocks} from 'blocks/blocks'
import {DP404pageTerm} from 'types/DPTypes'
import {Hero} from 'blocks/hero/hero'
import {Layout} from 'layout/layout'
import {Paragraph} from 'blocks/paragraph'
import {drupalLang} from 'util/locale'
import {usePathname} from 'next/navigation'

export const NotFoundPage: React.FC<{
	custom404s: DP404pageTerm[]
}> = ({custom404s}) => {
	const pathname = usePathname()
	const term = custom404s.find((term) => pathname.startsWith(term.path || ''))

	const pageTitle =
		drupalLang === 'nl' ? '404 - Pagina niet gevonden' : '404 - Page not found'

	return (
		<Layout>
			{term ? (
				<Blocks blocks={term.blocks}>
					{term.blocks.map((block) => (
						<Paragraph {...block} key={block.id} />
					))}
				</Blocks>
			) : (
				<Blocks blocks={[]}>
					<Hero title={pageTitle} />
				</Blocks>
			)}
		</Layout>
	)
}
